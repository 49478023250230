<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-psiholog">
        <a href="https://psihosexolog.ro/" target="_blank">
          <img src="@/assets/casestudy/logo-psiholog.svg" alt="Psiholog"></a>
        <span class="sep"></span>
        <p>A professional, warm cabinet where the people and their privacy is put on the first
          place. The cabinet services includes clinical psychology, psychological counselling,
          psychotherapy, clinical hypnosis, sexology. The cabinet can be found in Tirgu
          Mures or online if you visit the website. About Psih. dr. Radu Balanean would be a
          lot to write and you can find everything in the website that we designed and
          developed.</p>
      </div>
    </div>

    <div class="laptop-img psiholog-laptop">
      <div class="container">
        <img src="@/assets/casestudy/psiholog-preview.png" alt="Psiholog">
      </div>
    </div>

    <div class="container-about psiholog-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/psiho-diamond.svg" alt="Psiholog">
        <h3>About the project</h3>
        <p>Except from learning more about the professional cabinet of Psih. dr. Radu
          Balanean located in Tirgu Mures, now you can also schedule an appointment
          based on the type of service that you are looking for. The website displays a
          complete list and detailed info, including pricing about the services that are offered
          at this cabinet.
        </p>
      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-typography__content">
          <h3>Typography</h3>
          <div class="font-typo">
            <img src="@/assets/casestudy/myriad-font.svg" alt="Psiholog Font">
            <h3><span style="color:#eb7e35">Co</span><span style="color:#8ca41e">lo</span><span
                style="color:#8a8a8a">rs</span></h3>

            <div class="colors">
              <div class="fcolor psiho-p">Primary</div>
              <div class="scolor psiho-s">Secondary</div>
              <div class="tcolor psiho-t">Third</div>
            </div>

            <span class="sep vertical color-tag"></span>
            <div class="color-codes">
              <div class="ccodes">
                <p class="psiho-primary">#eb7e35</p>
                <p class="psiho-secondary">#8ca41e</p>
                <p class="psiho-third">#8a8a8a</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name: 'MStar'}" class="left">
              <img src="@/assets/casestudy/btn-mstar.png" alt="Mstar">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{ name: 'BellaClean'}" class="right">
              <img src="@/assets/casestudy/btn-bella.png" alt="Bella Clean">
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <ContactUs></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Home/Footer";
import LetsChat from "../../components/LetsChat";

export default {
  name: "Psiholog",
  components: {LetsChat, Footer, ContactUs}
}
</script>

<style lang="scss">
.logo-psiholog img {
  max-width: 320px;
}

.psiholog-laptop {
  background-color: #eb7e35;
}

.psiho-p {
  background-color: #eb7e35;
}

.psiho-primary {
  color: #eb7e35;
}

.psiho-s {
  background-color: #8ca41e;
}

.psiho-secondary {
  color: #8ca41e;
}

.psiho-t {
  background-color: #8a8a8a;
}

.psiho-third {
  color: #8a8a8a;
}

.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #eb7e35 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>